html,
body,
#root {
  width: 100%;
  height: 100%;
  position: relative;
  background: linear-gradient(0deg, #eef1f8, #eef1f8), #f6f9ff;
}
* { font-family: "Lato", sans-serif; }
a { text-decoration: none; }
